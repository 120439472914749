<template>
  <v-sheet
    id="scrolling"
    class="overflow-y-auto"
    style="height:100%; user-select:text;"
    color="grey  lighten-3"
  >
    <v-sheet color="white" elevation="1" width="100%" class="py-4">
      <v-container>
        <div class="text-h5 font-weight-light mb-2 ml-3">
          {{ $t("mail.518") }}
        </div>

        <!-- table -->
        <v-simple-table v-if="show">
          <template v-slot:default>
            <tbody>
              <tr v-for="item in headers" :key="item.name">
                <td v-if="isNotEmpty(item.value)" width="200px">
                  {{ item.name }}
                </td>
                <td
                  v-if="isNotEmpty(item.value)"
                  style="line-height:1.7; word-break:break-word;"
                  class="py-1"
                >
                  {{ item.value }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn
          v-if="show"
          large
          color="primary"
          elevation="0"
          class="mt-3 ml-3"
          @click="downloadMail()"
        >
          {{ $t("mail.166") }}
        </v-btn>
      </v-container>
    </v-sheet>

    <!-- -->
    <v-container>
      <v-sheet
        style="height:100%; user-select:text;"
        color="white"
        elevation="1"
        class="pa-8 mb-8"
      >
        <div
          style="white-space:pre-wrap; font-size:14px; font-family: dotum;"
          v-text="raw"
        ></div>
      </v-sheet>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapActions /*, mapGetters, mapMutations */ } from "vuex";
import i18n from "@/_locales";
import router from "@/commons/router";
import { getViewOriginal } from "@/mail/api/mail.api";
import { isEmpty } from "@/commons/utils/validation";
import { getDateWithTimeSecond } from "@/commons/utils/moment";

export default {
  data() {
    return {
      show: false,
      headers: [],
      raw: "",
      params: {
        isSharedMail: false,
        folderId: 0,
        mailId: 0,
        ownerId: 0
      }
    };
  },

  created() {
    this.setShowSplash(false);
  },
  async mounted() {
    const { params } = router.currentRoute;
    const {
      isSharedMail,
      folderId,
      mailId,
      ownerId,
      attachPosition
    } = JSON.parse(params.actionObj);

    this.params.isSharedMail = isSharedMail;
    this.params.folderId = folderId;
    this.params.mailId = mailId;
    this.params.ownerId = ownerId;
    this.params.attachPosition = attachPosition;

    const { data, status } = await getViewOriginal(
      isSharedMail,
      mailId,
      attachPosition,
      { ownerId, folderId }
    );

    if (status != 200 || !data.raw) {
      this.raw = i18n.t("mail.189");
      return;
    }

    // 해더
    this.headers = [
      {
        name: i18n.t("mail.517"),
        value: data.messageId
      },
      {
        name: i18n.t("mail.날짜"),
        value: getDateWithTimeSecond(data.receivedTimeMillis)
      },
      {
        name: i18n.t("mail.보낸사람"),
        value: data.from
      },
      {
        name: i18n.t("mail.받는사람"),
        value: data.to
      },
      {
        name: i18n.t("mail.참조"),
        value: data.cc
      },
      {
        name: i18n.t("mail.숨은 참조"),
        value: data.bcc
      },
      {
        name: i18n.t("mail.제목"),
        value: data.subject
      },
      {
        name: "SPF",
        value: data.spf
      },
      {
        name: "DKIM",
        value: data.dkim
      }
    ];

    // 원본 메시지
    this.raw = data.raw;

    // 보기
    this.show = true;
  },
  methods: {
    ...mapActions("splash", ["setShowSplash"]),
    isNotEmpty(s) {
      return !isEmpty(s);
    },
    downloadMail() {
      const {
        mailId,
        folderId,
        ownerId,
        isSharedMail,
        attachPosition
      } = this.params;
      const url = isSharedMail
        ? `${
            process.env.VUE_APP_API_SERVER_URL
          }/api/share/mails/download/eml/${mailId}/${attachPosition}?folderId=${folderId}&ownerId=${ownerId}&access_token=${localStorage.getItem(
            "access_token"
          )}`
        : `${
            process.env.VUE_APP_API_SERVER_URL
          }/api/mail/download/eml/${mailId}/${attachPosition}?access_token=${localStorage.getItem(
            "access_token"
          )}`;

      window.location.href = url;
    }
  }
};
</script>
